import { Injectable } from '@angular/core';
import { ApiService, AppService } from '@lc/core';
import { Ai, Preferences } from '../../../../core/src/lib/models/ai-model';

export interface GenerateOptions {
  generateHeadline: boolean;
  generateBody: boolean;
  prompts?: string[];
}

@Injectable({
  providedIn: 'root',
})
export class PropertyDescriptionService {
  constructor(
    private apiService: ApiService,
  ) { }

  public async generateDescription(
    orderId: string,
    productCode: string | undefined,
    options: GenerateOptions,
  ): Promise<{ body: string, headline: string, ai: Ai }> {
    const route = productCode
      ? `/orders/${orderId}/products/${productCode}/property-descriptions/generate`
      : `/orders/${orderId}/property-descriptions/generate`;

    try {
      return await this.apiService.create(route, options, { version: 'v2' });
    } catch (error) {
      throw error?.error?.error || Error('An error occurred generating the property description');
    }
  }

  public async updatePreferences(orderId: string, preferences: Partial<Preferences>) {
    const route = `/orders/${orderId}/property-descriptions/update-preferences`;
    try {
      return await this.apiService.patch(route, { preferences }, { version: 'v2' });
    } catch (error) {
      throw error?.error?.error || Error('An error occurred updating your preferences');
    }
  }

  public getAiRequests(ai: Ai, role?: 'agent' | 'coordinator') {
    role ??= AppService.isAgentApp ? 'agent' : 'coordinator';
    return ai?.userRequests.find((userRequest) => userRequest.role === role)?.descriptionCount ?? 0;
  }

  public getAiMaxRequests(ai: Ai) {
    const role = AppService.isAgentApp ? 'agent' : 'coordinator';
    return ai?.userRequests.find((userRequest) => userRequest.role === role)?.descriptionMax;
  }
}
