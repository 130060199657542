import { Component, NgZone, OnInit } from '@angular/core';
import {
  AppService, FeatureFlags, LaunchDarklyService, OktaAuthenticationProvider,
} from '@lc/core';
import { Subject, Subscription, takeUntil } from 'rxjs';

declare let ServiceNowChat;

@Component({
  selector: 'service-now-chat',
  templateUrl: './service-now-chat.component.html',
  styleUrls: ['./service-now-chat.component.css'],
})

export class ServiceNowChatComponent implements OnInit {
  private window: Window = window;
  private sessionStorage: Storage = sessionStorage;
  private serviceNowChatObj;
  private openChatStorageKey = 'open-chat-on-redirect';
  private snRedirectionCountStorageKey = 'service-now-redirect-count';
  private snRedirectParam = '/sn_va_web_client_login.do?sysparm_redirect_uri=';
  private serviceNowMcdUrl = null;
  private brandingKey;
  public serviceNowAuthUrl;
  private maxRedirection = 1;
  private skipLoadHistory;
  private snVaPath = '/uxasset/externals/now-requestor-chat-popover-app/index.jsdbx?sysparm_substitute=false';
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private readonly oktaAuthService: OktaAuthenticationProvider,
    private launchDarklyService: LaunchDarklyService,
  ) { }

  ngOnInit() {
    this.checkFeature();
  }

  checkFeature() {
    this.launchDarklyService.getFeature(FeatureFlags.OMNI_CHAT)
      .then((res) => {
        if (res && AppService.get('environment') !== 'local') {
          this.assignServiceNowVariables();
          this.checkIfNewLogin();
        }
      })
      .catch((error) => {
        console.error('Error fetching feature:', error);
      });
  }

  assignServiceNowVariables() {
    this.serviceNowMcdUrl = AppService.get('serviceNowMcdUrl');
    this.brandingKey = AppService.get('serviceNowBrandingKey');
    this.skipLoadHistory = true;
    this.serviceNowAuthUrl = `${this.serviceNowMcdUrl}${this.snRedirectParam}${encodeURIComponent(this.window.location.href)}`;
  }

  async checkIfNewLogin() {
    this.oktaAuthService.isNewLogin$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((isNewLogin) => {
        if (isNewLogin === true && localStorage.getItem('isNewLogin') !== 'false') {
          localStorage.setItem('isNewLogin', 'false');
          console.log('It is a service now new login');
          this.window.location.assign(this.serviceNowAuthUrl);
        } else if (localStorage.getItem('isNewLogin') === 'false' && isNewLogin) {
          console.log('It is not a new login service now');
        }
        this.injectSNScript();
      });
  }

  injectSNScript() {
    const SNRptPortableVAClientMcdUrl = `${this.serviceNowMcdUrl}${this.snVaPath}`;
    // Check if the script is already injected
    const existingScript = document.querySelector(`script[src="${SNRptPortableVAClientMcdUrl}"]`);
    if (existingScript) {
      console.log('ServiceNow VA script is already injected.');
      // You can handle this case as needed, maybe execute some callback function.
      return;
    }
    const snScript = document.createElement('script');
    snScript.src = SNRptPortableVAClientMcdUrl;
    snScript.type = 'module';
    snScript.async = true;
    document.getElementsByTagName('head')[0].appendChild(snScript);
    snScript.onload = (this.afterScriptLoaded).bind(this);
  }

  afterScriptLoaded = function () {
    this.setupServiceNowVA();
    setTimeout(() => {
      this.checkToOpenChat();
    });
  };

  checkToOpenChat() {
    const openChatFlag = this.sessionStorage.getItem(this.openChatStorageKey);
    if (openChatFlag === 'true') {
      this.serviceNowChatObj.open();
      this.sessionStorage.removeItem(this.openChatStorageKey);
    }
  }

  setupServiceNowVA = () => {
    this.serviceNowChatObj = new ServiceNowChat({
      instance: this.serviceNowMcdUrl,
      context: {
        skip_load_history: this.skipLoadHistory,
        branding_key: this.brandingKey,
      },
      branding: {
        bgColor: '#1F69FF',
        primaryColor: '#1F69FF',
        hoverColor: '#425989',
        activeColor: '#AAA',
        openIcon: 'omni_close_new.svg',
        closeIcon: 'omni_open_new.svg',
        sizeMultiplier: 1.6,
      },
      offsetX: 0,
      offsetY: 0,
      position: 'right',
    });
    window.addEventListener('message', this.snEventHandler);
  };

  snEventHandler = (messageEvent) => {
    let redirectionCount = Number(this.sessionStorage.getItem(this.snRedirectionCountStorageKey));
    this.serviceNowAuthUrl = `${this.serviceNowMcdUrl}${this.snRedirectParam}${encodeURIComponent(this.window.location.href)}`;

    /**
     * redirect to SSO login if the web client logs in but is logged in as a guest user(unauthenticated)
     * or, redirect to SSO login if the ServiceNow platform logs out from underneath the web client
     */
    if ((messageEvent.data.type === 'SESSION_CREATED' && messageEvent.data.authenticated === false)
      || messageEvent.data.type === 'SESSION_LOGGED_OUT') {
      redirectionCount += 1;
      if (redirectionCount <= this.maxRedirection) {
        this.sessionStorage.setItem(this.snRedirectionCountStorageKey, redirectionCount.toString());
        this.sessionStorage.setItem(this.openChatStorageKey, 'true');
        this.window.location.assign(this.serviceNowAuthUrl);
      } else {
        this.resetRedirectionCount();
      }
    } else if (messageEvent.data.type === 'SESSION_CREATED' && messageEvent.data.authenticated === true) {
      this.resetRedirectionCount();
    }
  };

  resetRedirectionCount() {
    this.sessionStorage.setItem(this.snRedirectionCountStorageKey, '0');
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
