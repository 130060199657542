<mat-toolbar class="mat-elevation-z1" *ngIf="getAssumedUser as assumedUser">
  <ng-container>
    <mat-toolbar-row id="obo-row" class="px-4 py-1 bg-warn fx-row x-space-between y-center">
      <div class="mat-body-1 m-0">

        <!-- Impersonating user: -->
        <span>
          ACTING ON BEHALF OF: {{assumedUser.profile?.preferredFirstName}} {{assumedUser.profile?.preferredLastName}}
        </span>

        <!-- Email Link -->
        <a *ngIf="assumedUser.profile?.preferredEmail" [href]="'mailto:' + assumedUser.profile.preferredEmail" [style.color]="'inherit'">
          (<u>{{assumedUser.profile.preferredEmail}}</u>)
        </a>

        <!-- Metro Name -->
        <span *ngIf="assumedUser.profile?.office?.metro?.metroName">
          - {{assumedUser.profile?.office?.metro?.metroName}}
        </span>

      </div>

      <!-- End Impersonation Button -->
      <button mat-button class="on-behalf-of" id="btn-exit-obo" aria-label="Close" (click)="endImpersonation()">
        <div class="fx-row y-center fx-gap-sm">
          <mat-icon class="material-icons-outlined">block</mat-icon>
          <span>Exit Acting Mode</span>
        </div>
      </button>

    </mat-toolbar-row>
  </ng-container>
</mat-toolbar>


      <!-- RSC Header for LC & DC apps. Currently RSC package doesn't support property binding -->
      <!-- So, we need to set app-key values on the tags -->

<div class="fx-row x-end y-center fx-gap-md"
 *ngIf= "appkey === 'Listing Concierge' || appkey === 'Listing Concierge Coordinator App' || appkey === 'Listing Concierge Administrator App' || appkey === 'Listing Concierge Photo App'">
  <rsc-header *ngIf="mounted && brandKey === 'cb'" profile-menu="true" app-key="30a0266a-6e65-46c6-9af4-229ca9b1ed35" brand-key="cb"
    full-width-layout="true" header-size-update="true">
    <lc-notifications-menu></lc-notifications-menu>
  </rsc-header>

  <rsc-header *ngIf="mounted && brandKey === 'bhg'" profile-menu="true" app-key="30a0266a-6e65-46c6-9af4-229ca9b1ed35" brand-key="bhg"
    full-width-layout="true" header-size-update="true">
    <lc-notifications-menu></lc-notifications-menu>
  </rsc-header>
</div>
<div class="fx-row x-end y-center fx-gap-md" *ngIf= "appkey === 'Design Concierge' || appkey === 'Design Concierge Designer App'">
  <rsc-header *ngIf="mounted && brandKey === 'cb'" profile-menu="true" app-key="2e9b20eb-1945-46f9-a394-a68418ddd647" brand-key="cb"
    full-width-layout="true" header-size-update="true">
  <lc-notifications-menu></lc-notifications-menu>
  </rsc-header>

  <rsc-header *ngIf="mounted && brandKey === 'bhg'" profile-menu="true" app-key="2e9b20eb-1945-46f9-a394-a68418ddd647" brand-key="bhg"
  full-width-layout="true" header-size-update="true">
<lc-notifications-menu></lc-notifications-menu>
</rsc-header>
</div>
