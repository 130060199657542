import { Deserializable } from './deserializable.model';
import { PhotographerAppointment } from './photographer-appointment.model';
import { TemplateInstance } from './template-instance.model';
import { StateTransition } from './status-history';
import { Media } from './media.model';
import { ServiceStatusType } from './service-status.type';
import { ProductKind } from './product-kind.enum';
import { ProductCategory } from './product.category';
import { ProductDetails } from './product-details.model';
import { Audit } from './audit.model';
import { VendorOverrides } from './vendor-overrides.model';
import { ProductOption } from './product-option.model';
import { ProductCode } from './product.code';
import { PhotoArrayType } from './photo-array.type';
import { ListingPhoto } from './listing-photo.model';
import { DesignDetails } from './product-design-details.model';
import { Fulfillment } from './fulfillment.model';
import { Ai } from './ai-model';

export class ProductInstanceApproval implements Deserializable<ProductInstanceApproval> {
  approvedByAgent: boolean;
  reason: string;
  notes: string;

  constructor(instance?: Partial<ProductInstanceApproval>) {
    this.deserialize(instance);
  }

  deserialize(input: Partial<ProductInstanceApproval>): ProductInstanceApproval {
    if (!input) { return this; }
    Object.assign(this, input);
    return this;
  }
}

export class PublishConsent implements Deserializable<PublishConsent> {
  consentAt: Date;
  consentBy: string;
  consentByFullName: string;

  constructor(instance?: Partial<PublishConsent>) {
    this.deserialize(instance);
  }

  deserialize(input: Partial<PublishConsent>): PublishConsent {
    if (!input) { return this; }

    Object.assign(this, input);
    return this;
  }
}

export class PublishedUri implements Deserializable<PublishedUri> {
  uriType: string;
  uri: string;

  constructor(instance?: Partial<PublishedUri>) {
    this.deserialize(instance);
  }

  deserialize(input: Partial<PublishedUri>): PublishedUri {
    if (!input) { return this; }

    Object.assign(this, input);
    return this;
  }
}

export class ProductQR implements Deserializable<ProductQR> {
  /** Reference to the product QR code */
  code: string;

  /** The url of the QR Code */
  resourceUrl: string;

  /** How this QR should be placed on the product */
  display: 'float' | 'hidden' = 'float';

  /** The placement of the QR on the product templates */
  placement?: {
    top?: number;
    left?: number;
    height?: number;
    width?: number;
    pageNumber?: number;
    absoluteRotationAngle?: number;
  };

  constructor(model?: Partial<ProductQR>) {
    this.deserialize(model);
  }

  deserialize(model?: Partial<ProductQR>): ProductQR {
    Object.assign(this, model);
    return this;
  }
}

export class ProductInstance implements Deserializable<ProductInstance> {
  title: string;
  description: string;
  code: ProductCode;
  kind: ProductKind;
  category: ProductCategory;
  infoLink: string;
  details: ProductDetails;
  approval: ProductInstanceApproval;
  thumbnailUri: string;
  publishedUris: PublishedUri[];
  marketingCopyHeadline: string[];
  marketingCopyBody: string[];
  optedOut: boolean;
  selectedTemplate: TemplateInstance;
  selectedTemplateCode: string;
  serviceAppointment: any;
  additionalUnits: number;
  totalQuantity: number;
  position: number;
  pageSizeChoice: '8x8' | '85x11' | '11x17' | 'other';
  paperTypeChoice: 'glossy' | 'matte' | 'soft-touch';
  publishConsents: PublishConsent[];
  media: Media;
  photos: ListingPhoto[] | { [key: string]: ListingPhoto[] };
  isAvailable: boolean;
  fulfillment: Fulfillment;
  finalPdfUrl: string;
  status: ServiceStatusType;
  statusHistory: StateTransition[];
  audit: Audit;
  contentWasModified: boolean;
  vendorDetails: any[];
  vendorOverrides?: VendorOverrides;
  advertisingChoices?: string[];
  designDetails?: DesignDetails[];
  customContactBlock?: string;
  customContactConsent?: any[];
  hiddenTags?: any[];
  qr?: ProductQR;
  ai?: Ai;
  reorders?: Fulfillment[];
  previewPdfUri?: string;

  constructor(data?: any) {
    this.deserialize(data || {});
  }

  deserialize(input: any): ProductInstance {
    Object.assign(this, input);
    this.designDetails = (this.designDetails || []).map((detail) => new DesignDetails(detail));

    if (this.serviceAppointment) {
      this.serviceAppointment = new PhotographerAppointment(this.serviceAppointment);
    }
    if (this.selectedTemplate) {
      this.selectedTemplate = new TemplateInstance(this.selectedTemplate);
    }
    if (this.details) {
      this.details = new ProductDetails(this.details);
    }
    if (this.approval) {
      this.approval = new ProductInstanceApproval(this.approval);
    }
    if (this.publishConsents) {
      this.publishConsents = this.publishConsents.map((consent) => new PublishConsent(consent));
    }
    if (this.media) {
      this.media = new Media(this.media);
    }
    if (this.statusHistory) {
      this.statusHistory = this.statusHistory.map((history) => new StateTransition(history));
    }

    if (this.photos?.length) {
      if (this.photos instanceof Array) {
        this.photos = this.photos.map((photo) => new ListingPhoto(photo));
      } else {
        for (const key in this.photos) {
          if (Object.prototype.hasOwnProperty.call(this.photos, key)) {
            const value = this.photos[key];
            this.photos[key] = (value || []).map((photo) => new ListingPhoto(photo));
          }
        }
      }
    }

    return this;
  }

  getPhotos(arrayType: PhotoArrayType) {
    if (this.photos instanceof Array) {
      return this.photos;
    }
    const photoObject = this.photos || {};
    return photoObject[arrayType];
  }

  /**
   * check if product transitioned from delivery to proofing
   */
  isProductTransitionedFromDeliveryToProofing() {
    for (let i = 0, len = this.statusHistory.length; i < len; i++) {
      if (this.statusHistory[i].transitionedTo === 'DELIVERY'
        && this.statusHistory[i + 1]
        && this.statusHistory[i + 1].transitionedTo === 'PROOFING') {
        return true;
      }
    }
    return false;
  }

  /**
   * Simple boolean check for Digital Product product.
   */
  isDigitalProduct() {
    return this.kind === ProductKind.PRODUCT && this.category === ProductCategory.DIGITAL;
  }

  /**
   * Simple boolean check for Video Service product.
   */
  isVideoService() {
    return this.kind === ProductKind.SERVICE && this.category === ProductCategory.VIDEO;
  }

  /**
   * Simple boolean check for Video Service product.
   */
  isAdvertisingProduct() {
    return this.code === ProductCode.PRINT_ADVERTISING;
  }

  /** Returns if the product is vendor printed */
  get isVendorPrinted() {
    return (this.category === ProductCategory.PRINT && this.details?.isVendorPrinted);
  }

  get allowAdditionalQuantity() {
    return this.details?.isVendorPrinted
      && this.isAvailable
      && !this.details?.isReplaced
      && (this.details?.unitPrice > 0 || this.details?.additionalUnitPrice > 0);
  }

  /**
   * Simple boolean check for Website product.
   */
  isWebsiteProduct() {
    return this.code === ProductCode.WEBSITE;
  }

  /**
   * Determines if the product is in the delivery loop. Applies to FULL / PARTIAL Service products.
   */
  isInDeliveryLoop() {
    return this.status === ServiceStatusType.PROOFING && this.statusHistory?.some((status) => status.transitionedTo === ServiceStatusType.DELIVERY);
  }

  /**
   * Returns the ProductOptions from the vendorOverrides first. Otherwise, get from the product details.
   */
  getProductOptions(): ProductOption[] {
    return ProductInstance.getProductOptions(this, this.vendorOverrides);
  }

  hasMarketingCopy() {
    // Returns true if the product has marketing copy information
    return this.category !== ProductCategory.VIDEO || this.details?.bodyLayouts?.length > 0;
  }

  /**
   * Returns the ProductOptions from the vendorOverrides first. Otherwise, get from the product details.
   * @param product The realized product instance
   * @param overrides The vendor overrides
   */
  static getProductOptions(product: ProductInstance, overrides?: VendorOverrides): ProductOption[] {
    // Assuming that an empty array [] for the modified options still counts as an override
    const modifiedOptions = overrides?.modifiedOptions?.map(
      (modifiedOption) => ProductInstance.combineModifiedOptionWithOriginal(modifiedOption, product),
    ) ?? [];
    return modifiedOptions.length ? modifiedOptions : product?.details?.options || [];
  }

  static combineModifiedOptionWithOriginal(modifiedOption: ProductOption, product: ProductInstance) {
    const original = (product?.details?.options || []).find((option) => {
      return option.excludeFromCount === modifiedOption.excludeFromCount
      && option.title === modifiedOption.title;
    });
    const combined = { ...original, ...modifiedOption };

    // Need to assign the options over the object itself instead of returning a new object. A new object breaks the code
    // because it is no longer attached to the marketing order that is being saved
    Object.assign(modifiedOption, combined);

    if (modifiedOption.isBundled) {
      // If the option is bundled, we need to make sure it is selected
      modifiedOption.selected = true;
    }
    return modifiedOption;
  }

  getPhotoType(): string {
    if (this.isDigitalProduct()) {
      return PhotoArrayType.CAROUSEL;
    } if (this.isVideoService()) {
      return PhotoArrayType.VIDEO;
    }
    return PhotoArrayType.DEFAULT;
  }

  get durationDescription() {
    return this.details.durationDescription;
  }
}
